import { lazy } from "react";

const Blogs = lazy(() => import("../pages/blogs"));

const Blog = lazy(() => import("../pages/blog"));

const About = lazy(() => import("../pages/about").then(module => {
  return { default: module.About }
}));

const Home = lazy(() => import("../pages/home").then(module => {
  return { default: module.Home }
}));

const Portfolio = lazy(() => import("../pages/portfolio").then(module => {
  return { default: module.Portfolio }
}));

const ContactUs = lazy(() => import("../pages/contact").then(module => {
  return { default: module.ContactUs }
}));

const NotFound = lazy(() => import("../pages/404"));

const Socialicons = lazy(() => import("../components/socialicons").then(module => {
    return { default: module.Socialicons }
  }));


export {Blogs,Blog,About,Home,Portfolio,ContactUs, NotFound,Socialicons}